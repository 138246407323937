import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { SiteDetailsTabProps } from '../types';
import TermsAndValuesList from './components/TermsAndValuesList/TermsAndValuesList';
import CoTerminusChecksPanel from './components/CoTerminusChecksPanel/CoTerminusChecksPanel';

const OverviewTab: React.FC<SiteDetailsTabProps> = ({ siteDetails, companyDetails }) => {
  return (
    <Box sx={{ flexGrow: 1 }} data-testid="overview-tab__component">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TermsAndValuesList siteId={siteDetails.id} companyId={companyDetails.id} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CoTerminusChecksPanel siteId={siteDetails.id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewTab;
