export type Crumb = {
  title: string;
  link?: string;
};
export type Crumbs = Crumb[];

type CrumbsBuilder = { (data?: unknown): Crumbs } | { (): Crumbs };

type ModuleId = 'asset-management' | 'operations-and-maintenance' | 'dashboard' | 'due-diligence' | 'my-portfolio';

type ModuleFeatures = 'ai-assistant';

interface RouteHandleParams {
  moduleId?: ModuleId;
  crumbsBuilder?: CrumbsBuilder;
  enabledFeatures?: ModuleFeatures[];
}

export class RouteHandle {
  private crumbsBuilder: CrumbsBuilder | null;
  private moduleId: string | null;
  private enabledFeatures: ModuleFeatures[];

  private constructor(
    moduleId: string | null,
    crumbsBuilder: CrumbsBuilder | null,
    enabledFeatures?: ModuleFeatures[]
  ) {
    this.moduleId = moduleId;
    this.crumbsBuilder = crumbsBuilder;
    this.enabledFeatures = enabledFeatures ?? [];
  }

  public buildCrumbs(data: unknown): Crumbs {
    const builder = this.crumbsBuilder;
    return builder ? builder(data) : [];
  }

  public getModuleId(): string | null {
    return this.moduleId;
  }

  public getEnabledFeaturesList(): ModuleFeatures[] {
    return [...this.enabledFeatures];
  }

  public static createHandle(params: RouteHandleParams) {
    const { moduleId, crumbsBuilder, enabledFeatures } = params;

    return new RouteHandle(moduleId ?? null, crumbsBuilder ?? null, enabledFeatures);
  }
}
