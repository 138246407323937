import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import { ApiClient } from '../../../../../../api';

import { AssetManagementSiteDetailsTabProps } from '../types';
import formFloatValue from '../../../../../../utils/formatters/formatFloatValue';
import formatPhoneNumber from '../../../../../../utils/formatters/formatPhoneNumber';
import formatCurrencyValue from '../../../../../../utils/formatters/formatCurrencyValue';
import formatPercentageValue from '../../../../../../utils/formatters/formatPercentageValue';

export const PasswordTextField: React.FC<{ password: string }> = ({ password }) => {
  const [isRevealed, setIsRevealed] = React.useState(false);

  const togglePasswordRevealed = React.useCallback(() => {
    setIsRevealed(isRevealed => !isRevealed);
  }, [setIsRevealed]);

  if (!password.length) return null;

  return (
    <>
      {isRevealed ? password : '*'.repeat(8)}
      <IconButton size="small" sx={{ ml: '4px' }} onClick={togglePasswordRevealed}>
        {isRevealed ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </>
  );
};

const HyperlinkField: React.FC<{ link: string }> = ({ link }) => (
  <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
    <Link href={link} rel="noreferrer" target="_blank">
      {link}
    </Link>
  </Box>
);

interface InfoBoxProps {
  title: string;
  infoTableData: {
    field: string;
    value: string | number | null;
    valueType?: 'plain' | 'hyperlink' | 'password';
    decoration?: 'underline';
  }[];
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, infoTableData }) => (
  <Box display="flex" flexDirection="column" flexGrow={1} padding="16px" border="1px solid #0000003B">
    <Typography variant="h6" mb="6px">
      {title}
    </Typography>
    <Table sx={{ width: '100%', height: 'auto', tableLayout: 'fixed' }} size="small">
      <TableBody>
        {infoTableData.map(({ field, value, valueType, decoration }) => (
          <TableRow key={field} sx={{ '& .MuiTableCell-root': { px: 0, pb: 0, pt: 1, border: 'none' } }}>
            <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
              {`${field}:`}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                overflow: 'hidden',
                textWrap: 'wrap',
                wordBreak: 'break-word',
                ...(decoration === 'underline' && { textDecoration: 'underline' })
              }}
            >
              {valueType === 'password' && typeof value === 'string' ? (
                <PasswordTextField password={value} />
              ) : valueType === 'hyperlink' && typeof value === 'string' ? (
                <HyperlinkField link={value} />
              ) : typeof value === 'number' ? (
                value
              ) : value && value.length > 120 ? (
                `${value.slice(0, 120)}...`
              ) : (
                value || '-'
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
);

export const OverviewTab: React.FC<AssetManagementSiteDetailsTabProps> = ({ siteDetails }) => {
  const { id: siteId } = siteDetails;

  const { data: siteData, isLoading: isLoadingSiteData } = useQuery({
    queryFn: () => ApiClient.assetManagement.siteInfo(siteId),
    queryKey: ['sites', 'info', siteId],
    throwOnError: true
  });

  if (isLoadingSiteData || !siteData) return null;

  const infoBoxesData: InfoBoxProps[] = [
    {
      title: 'Site Level Details',
      infoTableData: [
        { field: 'Site Name', value: siteData.site_level_details.name },
        { field: 'Project ID', value: siteData.site_level_details.project_id },
        { field: 'Status', value: siteData.site_level_details.status },
        { field: 'Address', value: siteData.site_level_details.address },
        { field: 'City', value: siteData.site_level_details.city },
        { field: 'State', value: siteData.site_level_details.state },
        { field: 'Zip Code', value: siteData.site_level_details.zip_code },
        { field: 'County', value: siteData.site_level_details.county },
        { field: 'Latitude/Longitude', value: siteData.site_level_details.lon_lat_url },
        { field: 'System Size kW DC', value: formFloatValue(siteData.site_level_details.system_size_dc) },
        { field: 'System Size kW AC ', value: formFloatValue(siteData.site_level_details.system_size_ac) },
        { field: 'PVSYST', value: siteData.site_level_details.pvsyst },
        { field: 'Greenhouse Gas Offset', value: siteData.site_level_details.greenhouse_gas_offset },
        { field: 'Incentive Program', value: siteData.site_level_details.incentive_program },
        {
          field: 'Data Acquisition System Provider (DAS)',
          value: siteData.site_level_details.das,
          decoration: 'underline'
        },
        { field: 'Account #', value: siteDetails.account },
        { field: 'Username', value: siteDetails.username },
        {
          field: 'Password',
          value: siteDetails.password,
          valueType: 'password'
        },
        {
          field: 'Year 1 Expected Production',
          value: formFloatValue(siteData.site_level_details.year_one_expected_production)
        },
        { field: 'Degradation Amount', value: formatPercentageValue(siteData.site_level_details.degradation_amount) },
        {
          field: 'Capacity as % of Total Portfolio',
          value: formatPercentageValue(siteData.site_level_details.capacity_as_percent_of_total_portfolio)
        }
      ]
    },
    ...(siteData.asset_overview
      ? [
          {
            title: 'Asset Overview',
            infoTableData: [
              { field: 'Module Quantity', value: siteData.asset_overview.module_quantity },
              { field: 'Inverter Quantity', value: siteData.asset_overview.inverter_quantity },
              { field: 'Project Type', value: siteData.asset_overview.project_type },
              { field: 'Mount Type', value: siteData.asset_overview.mount_type },
              { field: 'Battery Storage', value: siteData.asset_overview.battery_storage }
            ]
          }
        ]
      : []),
    ...(siteData.ownership
      ? [
          {
            title: 'Ownership',
            infoTableData: [
              { field: 'Ownership Structure', value: siteData.ownership.ownership_structure },
              { field: 'Hold Co', value: siteData.ownership.hold_co },
              { field: 'Project Co', value: siteData.ownership.project_co },
              { field: 'Guarantor', value: siteData.ownership.guarantor },
              { field: 'Tax Credit Fund', value: siteData.ownership.tax_credit_fund }
            ]
          }
        ]
      : []),
    ...(siteData.tax_equity
      ? [
          {
            title: 'Tax Equity',
            infoTableData: [
              { field: 'Tax Equity Fund', value: siteData.tax_equity.tax_equity_fund },
              { field: 'Tax Equity Provider', value: siteData.tax_equity.tax_equity_provider },
              {
                field: 'Tax Equity Buyout Amount',
                value: formFloatValue(siteData.tax_equity.tax_equity_buyout_amount)
              },
              { field: 'Tax Equity Buyout Date', value: siteData.tax_equity.tax_equity_buyout_date },
              { field: 'Tax Equity Pref Rate', value: formatPercentageValue(siteData.tax_equity.tax_equity_pref_rate) },
              { field: 'Smartsheet Data Tape', value: siteData.tax_equity.smartsheet_data_tape }
            ]
          }
        ]
      : []),
    ...(siteData.key_dates
      ? [
          {
            title: 'Key Dates',
            infoTableData: [
              { field: 'Mechanical Completion Date', value: siteData.key_dates.Mechanical_Completion_Date },
              { field: 'Substantial Completion Date', value: siteData.key_dates.Substantial_Completion_Date },
              { field: 'Final Completion Date', value: siteData.key_dates.Final_Completion_Date },
              { field: 'Permission to Operate', value: siteData.key_dates.Permission_to_Operate },
              { field: 'Placed in Service Date', value: siteData.key_dates.Placed_in_Service_Date },
              { field: 'Financial Close Date', value: siteData.key_dates.Financial_Close_Date }
            ]
          }
        ]
      : []),
    ...(siteData.o_and_m
      ? [
          {
            title: 'O&M',
            infoTableData: [
              { field: 'Provider', value: siteData.o_and_m.provider },
              { field: 'Address', value: siteData.o_and_m.contact },
              { field: 'Contact Name', value: siteData.o_and_m.contact_name },
              { field: 'Contact Email', value: siteData.o_and_m.contact_email },
              { field: 'Contact Phone #', value: formatPhoneNumber(siteData.o_and_m.contact_phone) },
              { field: 'Agreement Effective Date', value: siteData.o_and_m.agreement_effective_date },
              { field: 'O&M Rate', value: siteData.o_and_m.o_and_m_rate },
              { field: 'O&M Escalator', value: formatPercentageValue(siteData.o_and_m.o_and_m_escalator) },
              { field: 'Production Guarantee', value: siteData.o_and_m.production_guarantee }
            ]
          }
        ]
      : []),
    ...(siteData.interconnection_utility_provider
      ? [
          {
            title: 'Interconnection Utility Provider',
            infoTableData: [
              { field: 'Provider', value: siteData.interconnection_utility_provider.provider },
              { field: 'Address', value: siteData.interconnection_utility_provider.contact },
              { field: 'Contact Name', value: siteData.interconnection_utility_provider.contact_name },
              { field: 'Contact Email', value: siteData.interconnection_utility_provider.contact_email },
              {
                field: 'Contact Phone #',
                value: formatPhoneNumber(siteData.interconnection_utility_provider.contact_phone)
              },
              { field: 'PPA Effective Date', value: siteData.interconnection_utility_provider.ppa_effective_date },
              { field: 'PPA Term', value: siteData.interconnection_utility_provider.ppa_term },
              { field: 'Remaining PPA Term', value: siteData.interconnection_utility_provider.remaining_ppa_term },
              { field: 'Production Guarantee', value: siteData.interconnection_utility_provider.production_guarantee },
              {
                field: 'Interconnection Agreement Effective Date',
                value: siteData.interconnection_utility_provider.interconnection_agreement_effective_date
              },
              { field: 'Utility Rate', value: siteData.interconnection_utility_provider.utility_rate }
            ]
          }
        ]
      : siteData.interconnection
        ? [
            {
              title: 'Interconnection Utility Provider',
              infoTableData: [
                { field: 'Provider', value: siteData.interconnection.provider },
                { field: 'Address', value: siteData.interconnection.contact },
                { field: 'Contact Name', value: siteData.interconnection.contact_name },
                { field: 'Contact Email', value: siteData.interconnection.contact_email },
                { field: 'Contact Phone #', value: formatPhoneNumber(siteData.interconnection.contact_phone) },
                { field: 'PPA Effective Date', value: siteData.interconnection.ppa_effective_date },
                { field: 'PPA Term', value: siteData.interconnection.ppa_term },
                { field: 'Remaining PPA Term', value: siteData.interconnection.remaining_ppa_term },
                {
                  field: 'Production Guarantee',
                  value: siteData.interconnection.production_guarantee
                },
                {
                  field: 'Interconnection Agreement Effective Date',
                  value: siteData.interconnection.interconnection_agreement_effective_date
                },
                { field: 'Utility Rate', value: siteData.interconnection.utility_rate }
              ]
            }
          ]
        : []),
    ...(siteData.epc_contractor
      ? [
          {
            title: 'EPC Contractor',
            infoTableData: [
              { field: 'Provider', value: siteData.epc_contractor.provider },
              { field: 'Address', value: siteData.epc_contractor.contact },
              { field: 'Contact Name', value: siteData.epc_contractor.contact_name },
              { field: 'Contact Email', value: siteData.epc_contractor.contact_email },
              { field: 'Contact Phone #', value: formatPhoneNumber(siteData.epc_contractor.contact_phone) },
              { field: 'Agreement Effective Date', value: siteData.epc_contractor.agreement_effective_date }
            ]
          }
        ]
      : []),
    ...(siteData.community_solar_manager
      ? [
          {
            title: 'Community Solar Manager',
            infoTableData: [
              { field: 'Provider', value: siteData.community_solar_manager.provider },
              { field: 'Address', value: siteData.community_solar_manager.provider },
              { field: 'Contact Name', value: siteData.community_solar_manager.contact_name },
              { field: 'Contact Email', value: siteData.community_solar_manager.contact_email },
              { field: 'Contact Phone #', value: formatPhoneNumber(siteData.community_solar_manager.contact_phone) },
              {
                field: 'Community Solar Management Fee',
                value: formatCurrencyValue(siteData.community_solar_manager.community_solar_management_fee)
              },
              { field: 'Escalator', value: formatCurrencyValue(siteData.community_solar_manager.escalator) },
              { field: 'Escalator Effective', value: siteData.community_solar_manager.escalator_effective }
            ]
          }
        ]
      : []),
    ...(siteData.insurance_provider
      ? [
          {
            title: 'Insurance Provider',
            infoTableData: [
              { field: 'Provider', value: siteData.insurance_provider.provider },
              { field: 'Address', value: siteData.insurance_provider.contact }
            ]
          }
        ]
      : []),
    ...(siteData.vegetation_vendor
      ? [
          {
            title: 'Vegetation Vendor',
            infoTableData: [
              { field: 'Provider', value: siteData.vegetation_vendor.company },
              { field: 'Address', value: siteData.vegetation_vendor.contact },
              { field: 'Contact Name', value: siteData.vegetation_vendor.name },
              { field: 'Contact Email', value: siteData.vegetation_vendor.email },
              { field: 'Contact Phone #', value: formatPhoneNumber(siteData.vegetation_vendor.phone) }
            ]
          }
        ]
      : []),
    ...(siteData.offtaker
      ? [
          {
            title: 'Offtaker',
            infoTableData: [
              { field: 'Offtaker Name', value: siteData.offtaker.name },
              { field: 'Offtaker Type', value: siteData.offtaker.type },
              { field: 'Offtaker Credit Rating', value: siteData.offtaker.credit_rating },
              { field: 'Ratings Agency', value: siteData.offtaker.rating_agency },
              { field: 'Date of Rating', value: siteData.offtaker.date_of_rating }
            ]
          }
        ]
      : []),
    ...(siteData.compliance
      ? [
          {
            title: 'Compliance',
            infoTableData: [
              { field: 'Compliance Entity', value: siteData.compliance.entity },
              { field: 'Compliance Bank', value: siteData.compliance.bank },
              { field: 'Report Due Date', value: siteData.compliance.report_due_date },
              { field: 'Fiscal Year End', value: siteData.compliance.fiscal_year_end },
              { field: 'Tax Return Deadline', value: siteData.compliance.tax_return_deadline }
            ]
          }
        ]
      : []),
    ...(siteData.site_lease
      ? [
          {
            title: 'Site Lease',
            infoTableData: [
              { field: 'Landlord', value: siteData.site_lease.landlord },
              { field: 'Tenant', value: siteData.site_lease.tenant },
              { field: 'Property Size', value: formFloatValue(siteData.site_lease.property_size) },
              { field: 'Effective Date', value: siteData.site_lease.effective_date },
              { field: 'Rent Commencement', value: siteData.site_lease.rent_commencement },
              { field: 'Rent Amount', value: formatCurrencyValue(siteData.site_lease.rent_amount) },
              { field: 'Rent Escalator (Amount)', value: formatPercentageValue(siteData.site_lease.rent_escalator) },
              { field: 'Rent Escalator Effective Date', value: siteData.site_lease.rent_escalator_effective_date },
              { field: 'Lease Due Date', value: siteData.site_lease.lease_due_date },
              { field: 'Lease Payment Method', value: siteData.site_lease.lease_payment_method },
              { field: 'Lease Payment Frequency', value: siteData.site_lease.lease_payment_frequency },
              { field: 'Initial Term', value: siteData.site_lease.initial_term },
              { field: 'Renewal Terms', value: siteData.site_lease.renewal_terms },
              {
                field: 'Landlord Contact Phone #',
                value: formatPhoneNumber(siteData.site_lease.landlord_contact_phone)
              }
            ]
          }
        ]
      : [])
  ];

  return (
    <Box paddingTop={1} marginRight={-2} sx={{ flexGrow: 1 }}>
      <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={2}>
        {infoBoxesData.map(({ title, infoTableData }) => (
          <Paper key={title} elevation={0}>
            <InfoBox title={title} infoTableData={infoTableData} />
          </Paper>
        ))}
      </Masonry>
    </Box>
  );
};

export default OverviewTab;
